require('dotenv').config();
const expectedNetwork = process.env.REACT_APP_EXPECTED_NETWORK;
const etherscanLink = process.env.REACT_APP_ETHERSCAN_LINK;

export const getCookie = (cookieName) => {
  var name = cookieName + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(';');
  for (var i = 0; i < cookieArray.length; i++) {
      var cookieItem = cookieArray[i];
      while (cookieItem.charAt(0) === ' ') {
          cookieItem = cookieItem.substring(1);
      }
      if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
      }
  }
  return '';
}

export const setCookie = (cookieName, cookieValue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
  return;
}

const removeCookie = (cookieName) => {
  setCookie(cookieName, '', 0);
}

const getGenericErrorMessage = (errorMessage) => {
  let displayError = errorMessage ? String(errorMessage).replace('Returned error: execution reverted:', '') : '';
  displayError = displayError.replace('MetaMask Tx Signature: User denied transaction signature.', 'Transaction cancelled')
  
  return {
    success: false,
    status: "😥 Something went wrong: " + displayError
  }  
}

const checkMobile = () => {
  try {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    }
    if('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/)) {
      return true;
    }
  }
  catch(err) {
    return false;
  }

  return false;
}

export const getInstallMessage = () => {
  if(checkMobile()) {
    return {   
      address: "",
      isMobile: true,
      mobileLink: 'https://metamask.app.link/dapp/www.suddenlyunicorns.com/',
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            You must install 
            {" "}
            <a target="_blank" href={`https://metamask.app.link/dapp/www.suddenlyunicorns.com/`} rel="noreferrer noopener">
              Metamask app
            </a>
            , a virtual Ethereum wallet first. <br></br>
            And access this site from the built-in browser of Metamask (In the app, click the hamburger menu on top left and select browser) 
          </p>
        </span>
      ),
    };
  } else {
    return {   
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            You must install 
            {" "}
            <a target="_blank" href={`https://metamask.io/download.html`} rel="noreferrer noopener">
              Metamask
            </a>
            , a virtual Ethereum wallet in your browser.
          </p>
        </span>
      ),
    };
  }
}

export const authenticate = async () => {
  if (window.ethereum) {
    if(window.ethereum.selectedAddress) {
      const sessionOptions = {
        method: 'get',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Authorization': 'Bearer ' + getCookie('jwt'),
        }
      };

      try {
        let sessionResponse = await fetch(`${process.env.REACT_APP_TOKENAPI_URL}/session?addr=${window.ethereum.selectedAddress}`, sessionOptions);
        let jwt = await sessionResponse.text();
        setCookie('jwt', jwt, 1);
        let signatureAddress = window.ethereum.selectedAddress;
        let authResponse = await window.ethereum.request(
          {
              method: "personal_sign",
              params: [`Suddenly Unicorns here!\n\nThis request is for authenticating your wallet and making sure that you are holding a Suddenly Unicorns NFT.\n\nIt will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet Address: ${window.ethereum.selectedAddress}\n\nSession ID: ${jwt}`, window.ethereum.selectedAddress, ""]
          })
        const signatureOptions = {
          method: 'get',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('jwt')
          }
        };
        let signatureResponse = await fetch(`${process.env.REACT_APP_TOKENAPI_URL}/signature?sig=${authResponse}&addr=${signatureAddress}`, signatureOptions);
        let signatureJSON = await signatureResponse.json();
        return {
          address: "",
          authenticated: signatureJSON.authenticated,
          status: signatureJSON.response,
        };
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
      return;
    }
    else {
      connectWallet();
    }
  }
  else {
    return getInstallMessage();
  }
}

export const killSession = async () => {
  const sessionOptions = {
    method: 'get',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getCookie('jwt')
    }
  };
  const killSessionResponse = await fetch(`${process.env.REACT_APP_TOKENAPI_URL}/killSession`, sessionOptions);
  let killSessionResponseJson = await killSessionResponse.json();
  removeCookie('jwt');
  return killSessionResponseJson
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        address: addressArray[0],
      };
      if(window.ethereum.chainId !== expectedNetwork) {
        obj.status = 'Please switch to mainnet to continue';
      }
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return getInstallMessage();
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the button below.",
        };
      }
    } catch (err) {
      if(err.code === -32002) {
        return {
          address: "",
          status: "😥 Connection in progress. Please confirm connection from Metamask extension.",
          error: true
        };
      }
      else {
        return {
          address: "",
          status: "😥 " + err.message,
          error: true
        };
      }
    }
  } else {
    return getInstallMessage();
  }
};

export const mintNFT = async(tokenCount) => {
  //error handling
  if(!window.ethereum) {
    return getInstallMessage();
  }

  if(window.ethereum && window.ethereum.chainId !== expectedNetwork) {
    return {
      success: false,
      status: '❗Please switch to mainnet for minting your tokens.'
    }
  }

  if (tokenCount < 0 || tokenCount > process.env.REACT_APP_MAX_MINT_COUNT) { 
      return {
          success: false,
          status: "❗Please enter a valid token count.",
      }
  }

  let tx;

  try{
    let response = await fetch(`${process.env.REACT_APP_API_URL}/mintParameters?tokenCount=${tokenCount}&selectedAddress=${window.ethereum.selectedAddress}`, { 
      method: 'get',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    let responseJSON = await response.json();

    if(responseJSON.error) {
      if(responseJSON.errorType === 'Gas estimation') {
        return getGenericErrorMessage(responseJSON.errorMessage);
      }
      else {
        console.log(`${responseJSON.errorType}: ${responseJSON.errorMessage}`)
        return getGenericErrorMessage();
      }
    }

    tx = responseJSON.tx;
  }
  catch(error) {
    console.log(error);
    return getGenericErrorMessage();
  }

  try {
      window.dispatchEvent(new Event('removeSpinner'));
      // overwriting values for safety
      tx.contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [tx],
          });
      let transactionLink = etherscanLink + txHash;
      return {
          success: true,
          status:  (
            <span>
              <p>
                {" "}
                ✅ Check out your transaction on Etherscan:
                {" "}
                <a target="_blank" href={transactionLink} rel="noreferrer noopener">
                  {transactionLink}
                </a>
              </p>
            </span>
          )
      }
  } catch (error) {
      return getGenericErrorMessage(error.message)
  }
  }
