const Spinner = () => {
    return (
        <div>
            <div className="spinner-overlay">
            </div>
            <div className="spinner" dangerouslySetInnerHTML={{ __html: '<div> </div>'.repeat(9) }}>
            </div>
        </div>
    );
};

export default Spinner;
