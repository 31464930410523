import { useState } from "react";

const Navigation = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(false)
  const links = ['home','mint','links','roadmap','faq','team'];
  const dappLink = process.env.REACT_APP_DAPP_WEBSITE;

  const handleMenuClick = (event) => {
    const targetLink = event.target.closest('a');
    if(targetLink && targetLink.classList && targetLink.classList.contains('button-link')) {
      window.location = targetLink.href;
      return;
    }
    
    if(window.innerWidth - event.pageX < 45){
      props.unicornRightRef.current.classList.add('unicorn-colorful');
    }
    else if(event.pageX < 45) {
      props.unicornLeftRef.current.classList.add('unicorn-colorful');
    }
    
    if(event.target.href) {
      event.preventDefault();

      if(navbarOpen) {
        // Mobile
        setNavbarOpen(false);
        document.getElementsByTagName('body')[0].classList.remove('disable-scroll')
        window.location = event.target.href;
        setTimeout(() => {
          // to cover edge cases
          document.getElementsByTagName('body')[0].classList.remove('disable-scroll')
        }, 500);
      }
      else {
        // Desktop
        document.getElementsByName(event.target.title)[0].scrollIntoView({behavior: 'smooth' });
      }
    }
  };

  const handleToggle = () => {
    if (!navbarOpen) {
      setScrollPosition(window.scrollY);
      setTimeout(() => {
        document.getElementsByTagName('body')[0].classList.add('disable-scroll')
      }, 300);
    } else {
      document.getElementsByTagName('body')[0].classList.remove('disable-scroll')
      window.scrollTo(0, scrollPosition);
      setTimeout(() => {
        document.getElementsByTagName('body')[0].classList.remove('disable-scroll')
      }, 300);
    }

    setNavbarOpen(!navbarOpen)
  }

  const handleNavigationConnectWalletPressed = (event) => {
    props.connectWalletPressed(event, true) // second parameter is isNavigation
  }

  return (
    <div id="navigationContainer" onClick={(event) => handleMenuClick(event)}>
      <div className="hamburger-container"  onClick={handleToggle} ref={props.hamburgerRef}>
        <div className={`hamburger ${navbarOpen ? "open" : ""}`}
          dangerouslySetInnerHTML={{ __html: '<span></span>'.repeat(9) }}>
        </div>
      </div>

      <div className="header-unicorn unicorn-left" ref={props.unicornLeftRef} >  </div>
      <div className="header-unicorn unicorn-right" ref={props.unicornRightRef}>  </div>
      <div className="mobile-title"> 
        <p>
          <a href="#home" title="home"> Suddenly Unicorns </a>
        </p>
      </div>
      <div className={`navbar-container ${navbarOpen ? "show" : ""}`}>
          <ul className="navbar">
              {links.map((link) => {
                return (
                  <li key={link} className={`header-${link}`}> <a href={link !== 'dapp' ? `#${link}`: dappLink} title={link}>{link} </a> </li>
                )
              })}

              <li key="dapp" className="header-button">
                <a className="button-link" href={dappLink} target="_blank" rel="noreferrer nofollow">
                  <button className="dapp-button">
                        <span>Launch DAPP</span>
                  </button>
                </a>
              </li>

              <li key="wallet" className="header-button header-wallet">
                {props.walletAddress.length > 0 ? (
                  <button className="connected-wallet-button">
                    <span> 
                      {"Connected: " +
                      String(props.walletAddress).substring(0, 6) +
                      "..." +
                      String(props.walletAddress).substring(38)}
                    </span>
                  </button>
                ) : (
                  <button className="wallet-button" onClick={(event) => handleNavigationConnectWalletPressed(event)}>
                    <span>Connect Wallet</span>
                  </button>
                )}
              </li>
          </ul>
      </div>
  </div>
  );
};

export default Navigation;