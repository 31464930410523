import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { ParallaxBanner } from 'react-scroll-parallax';
import UnicornPixelate from "../components/UnicornPixelate";
// import unicornUnrevealed from "../images/unrevealed.png";

const scrollToMint = (e) => {
    e.preventDefault();
    document.getElementById('mintAnchor').scrollIntoView({behavior: 'smooth' });
}

const Landing = React.forwardRef((props, ref) => {
    const dappLink = process.env.REACT_APP_DAPP_WEBSITE;

    return (
        <section id="landingContainer" className="full-width">
            <Parallax translateY={[-40, 40]} className="landing-parallax-container">
                <div className="landing-top-container">
                    <div className="title-container">
                        <h1> Suddenly Unicorns </h1>
                        <div>
                            <p className="h1 title" translate="no">Suddenly</p>
                            <p className="h1 title" translate="no">Unicorns</p>
                        </div>
                    </div>
                    <div className="pixel-unicorn-container" ref={ref}>
                        <UnicornPixelate />
                    </div>
                </div>
                <div className="landing-button-container">
                    {/* <button className="landing-button mint-button" onClick={(event) => scrollToMint(event)}>
                        <span> <span className="additional flip">🦄</span> Mint Your Token <span className="additional">🦄</span>  </span> 
                    </button> */}
                    <a href={`${dappLink}mint`} className="button-link" target="_blank" rel="noreferrer noopener">
                        <button className="landing-button mint-button">
                            <span> <span className="additional flip">🦄</span> Mint Your Token <span className="additional">🦄</span>  </span> 
                        </button>
                    </a>
                    
                    <a href={dappLink} className="button-link" target="_blank" rel="noreferrer noopener">
                        <button className="landing-button dapp-button">
                            <span> <span className="additional flip">💎</span> Launch DAPP <span className="additional">💎</span>  </span> 
                        </button>
                    </a>
                </div>
            </Parallax>
            <ParallaxBanner className="rainbow-container flipped aspect-[2/1]"
                layers={[
                    { image: props.cloudsUrl, speed: -15, style:{inset:'-50px 0'} },
                    { image: props.rainbowUrl, speed: 5 , style:{inset:'-20px 0'} },
                ]}
                style={{ aspectRatio: '2 / 1'}}
            />
    </section>
    );
});

export default Landing;