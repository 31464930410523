import "../scss/unicornPixelate.scss";
import unicornEasterEye from "../images/7.png";

function eyeClicked() {
    try {
        document.getElementById('unicorn-eye').classList.add('show');
    }
    catch(err){

    }
}

const UnicornPixelate = () => {
    return (
    <div className="pixel-unicorn">
        {/* Row 1 */}
        <div className="row row-1">
            <div><div className="horn top left"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 2 */}
        <div className="row row-2">
            <div><div className="horn top left"></div></div>
            <div><div className="horn top left"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body top right"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 3 */}
        <div className="row row-3">
            <div><div className="transparent"></div></div>
            <div><div className="horn"></div></div>
            <div><div className="horn"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 4 */}
        <div className="row row-4">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="horn"></div></div>
            <div><div className="horn"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 5 */}
        <div className="row row-5">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="horn"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 6 */}
        <div className="row row-6">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 7 */}
        <div className="row row-7">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div> <div className="eye" id="unicorn-eye" onClick={() => eyeClicked()}>
                {/* <img className="eyeImage" src={unicornEasterEye} alt="Suddenly Unicorns 8" /> */}
            </div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 8 */}
        <div className="row row-8">
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="transparent"></div></div>
        </div>

        {/* Row 9 */}
        <div className="row row-9">
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
        </div>

        {/* Row 10 */}
        <div className="row row-10">
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="body"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
        </div>

        {/* Row 11 */}
        <div className="row row-11">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair1"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
        </div>

        {/* Row 12 */}
        <div className="row row-12">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body"></div></div>
            <div><div className="hair2"></div></div>
            <div><div className="hair1"></div></div>
        </div>

        {/* Row 13 */}
        <div className="row row-13">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body bottom right"></div></div>
            <div><div className="hair1 bottom right"></div></div>
        </div>

        {/* Row 14 */}
        <div className="row row-14">
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="transparent"></div></div>
            <div><div className="body bottom right"></div></div>
        </div>
    </div>
    );
};
export default UnicornPixelate;
