import React from 'react';
import { lazy, Suspense } from "react";
import { Parallax } from 'react-scroll-parallax';
import unicornGif from "../images/unicorn.gif";

const Intro = React.forwardRef((props, ref) => {
    
    return (
        <section id="introContainer" className='full-width'>
            <div className="column-2">
                <div className="padding">
                    <Parallax translateY={[15, -15]}>
                        <div className="gif-container">
                            <img width="200px" height="200px" src={unicornGif} alt="Suddenly Unicorns GIF" /> 
                            {/* <img width="400px" height="400px" src={unicornUnrevealed} alt="Suddenly Unicorns" /> */}
                        </div>
                        <div>
                            <h2>
                                We are
                            </h2>
                            <p> Suddenly Unicorns. An NFT project with <strong>11111</strong> unique Unicorns on the Ethereum blockchain. Compliant with 
                                <span> <a target="_blank" href="https://ethereum.org/en/developers/docs/standards/tokens/erc-721/" rel="noreferrer noopener">the ERC721 standard</a>. </span>  
                            </p>
                            <p> Each Unicorn has unique characteristics and accessories. Mint them and they become reality. </p>
                            <p> And some of them come with a <strong>little</strong> surprise. </p>
                        </div>
                        <div>
                            <h2>
                                All Unicorns are special, 
                            </h2>
                            <p> but some Unicorns are more special than others. </p>
                            <p> There are five types of <span className="emphasize"> Rare Unicorns </span> and they can't wait to be unlocked.</p>
                        </div>
                    </Parallax>
                </div>
            </div>
        </section>
    );
});

export default Intro;