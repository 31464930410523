import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import App from './App';
import Token from './Token';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

try {
  if(process.env.REACT_APP_ENABLE_SENTRY === "true") {
    Sentry.init({
      dsn: "https://dfe38012a9bf424abbd6fde208123b7a@o1140617.ingest.sentry.io/6197949",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
}catch(error) {
  console.log('Sentry issue', error)
}

ReactDOM.render(
  <React.StrictMode>
    <ParallaxProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/*" element={<App />} />
          <Route exact path="/token" element={<Navigate to="/token/1" />} />
          <Route exact path="/tokens" element={<Navigate to="/tokens/1" />} />
          <Route path="/token/:tokenId" element={<Token />} />
          <Route path="/tokens/:tokenId" element={<Token />} />
          <Route path="/tokenRefresh/:tokenId" element={<Token refresh={true}/>} />
        </Routes>
      </Router>
    </ParallaxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
