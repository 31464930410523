import { useState, useEffect, lazy, Suspense} from "react";
import { mintNFT} from "../js/interact.js"
const Spinner = lazy(() => import("./Spinner"));
const Celebration = lazy(() => import("./Celebration"));

const MintingForm = (props) => {  
  const [tokenCount, setTokenCount] = useState(1);
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [celebrationActive, setCelebrationActive] = useState(false);
  const tokenPrice = process.env.REACT_APP_TOKEN_PRICE;
  const dappLink = process.env.REACT_APP_DAPP_WEBSITE;
  let pixelInterval;
  let floatingIntervalTop;
  let floatingIntervalBottom;

  const incrementTokenCount = (event) => {
    event.preventDefault();
    const maxTokens = process.env.REACT_APP_MAX_MINT_COUNT;
    if (tokenCount < maxTokens) {
      setTokenCount(Number(tokenCount) + 1);
    }
  };

  const decrementTokenCount = (event) => {
    event.preventDefault();
    if (tokenCount > 1) {
      setTokenCount(Number(tokenCount) - 1);
    }
  };

  const activateCelebration = () => {
    setCelebrationActive(true);
    let headerUnicorns = document.getElementsByClassName('header-unicorn');
    headerUnicorns[0].classList.add('unicorn-colorful');
    headerUnicorns[1].classList.add('unicorn-colorful');

    pixelInterval = setInterval(function(){
      if(document.getElementById('celebrationPixelUnicorn') !== null) {
        clearInterval(pixelInterval);
        document.getElementById('celebrationPixelUnicorn').classList.add('complete');
        floatingIntervalTop = setInterval(function(){
          if(document.getElementById('celebrationFloatingUnicornsTop') !== null) {
            document.getElementById('celebrationFloatingUnicornsTop').classList.add('active');
            clearInterval(floatingIntervalTop);
          }
        }, 2);
    
        floatingIntervalBottom = setInterval(function(){
          if(document.getElementById('celebrationFloatingUnicornsBottom') !== null) {
            document.getElementById('celebrationFloatingUnicornsBottom').classList.add('active');
            clearInterval(floatingIntervalBottom);
          }
        }, 2);
      }
    }, 1.5);
  }

  const mint = async () => {
    setSpinnerActive(true);
    setTimeout(() => {
      // to cover edge cases
      setSpinnerActive(false);
    }, 5000);
    const response = await mintNFT(tokenCount);
    setSpinnerActive(false);
    props.setStatusChild(response.status);
    if(response.success) {
      activateCelebration();
    }
  };

  const onMintPressed = async (event) => {
    event.preventDefault();
    if(props.walletAddress.length === 0) {
      await props.connectWalletPressed(event);
    }
    mint();
  }

  const removeCelebration = () => {
    document.getElementById('celebrationPixelUnicorn').classList.remove('complete');
    setTimeout(() => {
      setCelebrationActive(false)
    }, 1000);
  }

  const removeSpinner = () => {
    window.addEventListener('removeSpinner', setSpinnerActive(false));
  }

  useEffect(() => {
    removeSpinner();
    window.addEventListener("removeSpinner", removeSpinner);
    return () => window.removeEventListener("removeSpinner", removeSpinner);
  },[]);
  
  return (
    <section id="mintFormContainer" ref={props.unicornMintRef}>
      <a href="#mint" className="anchor-target" name="mint" id="mintAnchor">mint</a>
      <div className="">
          <div className="">
            <h2> Minting </h2>
            <p>
              Minting is {tokenPrice} ETH each. You can mint up to {process.env.REACT_APP_MAX_MINT_COUNT} Suddenly Unicorns NFTs.
            </p>
        </div>
        <div className="">
            <form>
              <div>
                <h3>You can mint your tokens on our DAPP</h3>
                {/* <div className="form-buttons">
                  <button className="decrement-button" onClick={(event) => decrementTokenCount(event)}>
                    <span> <span className="additional">🦄</span> </span>{" "}
                  </button>
                  <p className="token-count">{tokenCount}</p>
                  <button className="increment-button" onClick={(event) => incrementTokenCount(event)}>
                    <span> <span className="additional">🦄 </span></span>{" "}
                  </button>
                </div> */}
              </div>
            
              <a href={`${dappLink}mint`} className="button-link" target="_blank" rel="noreferrer noopener">
                    <button type="button" className="landing-button dapp-button mint-button" style={{marginTop: "24px"}}>
                        <span> <span className="additional flip">💎</span> Launch DAPP <span className="additional">💎</span>  </span> 
                    </button>
                </a>
              {/* <button className="mint-button" onClick={(event) => onMintPressed(event)}>
                <span> Mint NFT<span className="additional plural">{tokenCount > 1 ? 's' : ''}</span></span>
              </button>
              <p className="total-price">TOTAL: {(tokenCount * Number(tokenPrice)).toFixed(3)} ETH + GAS</p>
              <p id="status" className={`${(props.status !== undefined && props.status != '') ? "has-status" : ""}`}>{props.status}</p>
              
              {props.walletAddress.length > 0 ? (
                  <button className="connected-wallet-button">
                    <span> 
                      {"Connected: " +
                      String(props.walletAddress).substring(0, 6) +
                      "..." +
                      String(props.walletAddress).substring(38)}
                    </span>
                </button> */}
              {/* ) : (
                <button className="wallet-button" onClick={(event) => props.connectWalletPressed(event)}>
                  <span>Connect Wallet</span>
                </button>
              )} */}
            </form>
        </div>
      </div>
      <Suspense fallback={<span></span>}>
        {spinnerActive ? ( <Spinner/>) : ''}
        {celebrationActive ? ( <Celebration removeCelebration={removeCelebration} />) : ''}
      </Suspense>
  </section>
  );
};

export default MintingForm;