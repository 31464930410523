import React, { useEffect, useState }  from 'react';
import { connectWallet, getCurrentWalletConnected, getInstallMessage, authenticate, killSession} from "./js/interact.js"
import { Outlet } from "react-router-dom";
import { useParams } from "react-router";
import Spinner from "./components/Spinner"
import './scss/token.scss';
import lazysizes from 'lazysizes';

const Token = (props) => {
    let { tokenId } = useParams();
    const dappLink = process.env.REACT_APP_DAPP_WEBSITE;
    window.location = `${dappLink}tokens/${tokenId}`;
    const expectedNetwork = process.env.REACT_APP_EXPECTED_NETWORK;

// State Variables
    const [walletAddress, setWallet] = useState(false);
    const [status, setStatus] = useState(false);
    const [authenticated, setAuthentication] = useState(false);
    const [tokenData, changeTokenData] = useState(false);
    const [hasError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [holderNfts, changeHolderNfts] = useState(false);

    function getCookie(cookieName){
        var name = cookieName + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookieArray = decodedCookie.split(';');
        for (var i = 0; i < cookieArray.length; i++) {
            var cookieItem = cookieArray[i];
            while (cookieItem.charAt(0) === ' ') {
                cookieItem = cookieItem.substring(1);
            }
            if (cookieItem.indexOf(name) === 0) {
                return cookieItem.substring(name.length, cookieItem.length);
            }
        }
        return '';
    }

    function addWalletListener() {
        if (window.ethereum) {
        window.ethereum.on("chainChanged", () => {
            killSessionPressed();
            if(window.ethereum.chainId !== expectedNetwork) {
                setStatus('❗Please switch to mainnet to continue');
            }
            else {
                setStatus();
            }
        });
    
        window.ethereum.on("accountsChanged", (accounts) => {
            killSessionPressed();
            if (accounts.length > 0) {
                setWallet(accounts[0]);
            } else {
                setWallet("");
            }
        });
        } else {
            setStatus(getInstallMessage().status);
        }
    }
    
    const connectWalletPressed = async (event) => {
        event.preventDefault();
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);    
        return;
    };

    const killSessionPressed = async (event) => {
        if(event) {
            event.preventDefault();
        }
        const killSessionResponse = await killSession();
        if(killSessionResponse.success) {
            getToken();
            setAuthentication(false);
        }
        setStatus();
        return;
    };

    useEffect(() => {
        async function getCurrentWalletConnectedAsync() {
            const { address, status, error } = await getCurrentWalletConnected();
            setWallet(address);
            if(error) {
                setStatus(status);
            }
            addWalletListener();
            if(address.length > 0 && window.ethereum.chainId !== expectedNetwork) {
                setStatus('❗Please switch to mainnet to continue');
            }
        }
        getCurrentWalletConnectedAsync();
    }, []);

    async function getToken() {
        // Fetch post using the tokenId
        try { 
            let response = await fetch(`${process.env.REACT_APP_TOKENAPI_URL}/tokens/${tokenId}/${props.refresh === true}`, { 
                method: 'get',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie('jwt')
                }
            });
            let responseJSON = await response.json();
            if(responseJSON.error) {
                setError(true);
                setErrorMessage(responseJSON.errorMessage);
            }
            else {
                changeTokenData(responseJSON.tokenMetadata);
                if(responseJSON.authenticated) {
                    setAuthentication(true);
                    changeHolderNfts(responseJSON.holderNfts);
                }
            }
        }
        catch(error) {
            console.log(error);
            setError(true);
            setErrorMessage(`Problem retrieving token #${tokenId}, please try again later`);
        }
    }

    useEffect(() => {
        getToken();
    }, [tokenId]);

    const authenticatePressed = async (event) => {
        event.preventDefault();
        const authenticateResponse = await authenticate();
        setStatus(authenticateResponse.status);
        if(authenticateResponse.authenticated) {
            getToken();
            setAuthentication(true);
        }
        return;
    };

    return (
        <section id="tokenContainer">
            <p className="hidden">Token {tokenId}</p>

            {tokenData ? (
                <div className="page-container">
                    {walletAddress.length > 0 ? (
                        <div className="wallet-button-container">
                            {authenticated ? (
                                <div className="wallet-button-authenticated-container">
                                    <button className="wallet-button" onClick={(event) => killSessionPressed(event)}>
                                        <span>Revoke Authentication</span>
                                    </button>

                                    <button className="connected-wallet-button">
                                        <span> 
                                            Authenticated
                                        </span>
                                    </button>
                                </div>
                            ) : (
                                <button className="wallet-button" onClick={(event) => authenticatePressed(event)}>
                                    <span>Token Holder Authentication</span>
                                </button>
                            )}

                            <button className="connected-wallet-button">
                                <span> 
                                {"Connected: " +
                                String(walletAddress).substring(0, 6) +
                                "..." +
                                String(walletAddress).substring(38)}
                                </span>
                            </button>
                        </div>
                    ) : (
                        <div className="wallet-button-container">
                            <button className="wallet-button" onClick={(event) => connectWalletPressed(event)}>
                                <span>Connect Wallet</span>
                            </button>
                        </div>
                    )}
                    <p id="status" className={`${(status !== undefined && status != '') ? "has-status" : ""}`}>{status}</p>
                    <h1> {tokenData.title ? tokenData.title : `Suddenly Unicorns #${tokenId}` } </h1>
                    {authenticated ? (
                        <h4> This is the final rarity of token #{tokenId}.</h4>
                    ) : ('')}
                    {tokenData.metadata.attributes.length ? (
                        <div className="token-details-container">
                            <div className="media-block">
                                <div className="image-container">
                                    <img className="main" src={tokenData.metadata.image} alt="Minted Suddenly Unicorns Token"/>
                                    {/* <img className="lazyload accessory" data-src={require("./images/accessories/eye_accessory/pixil-layer-heart_glasses.png").default} alt="Suddenly Unicorns rotating a rainbow" /> */}
                                    {/* <img className="lazyload accessory" data-src={require("./images/accessories/eye_accessory/pixil-layer-shutter_shades.png").default} alt="Suddenly Unicorns rotating a rainbow" /> */}
                                    {/* <img className="lazyload accessory" data-src={require("./images/accessories/neck_accessory/pixil-layer-necktie.png").default} alt="Suddenly Unicorns rotating a rainbow" /> */}
                                </div>
                            </div>
                            <div className="attributes-block">
                                <div className="attributes-container">
                                    {tokenData.metadata.attributes.map(({trait_type, value, rarity}) => {
                                        return (
                                            <div className="attribute-item" key={trait_type+value}>
                                                <div className="attribute-item-content" key={trait_type+value+'c'}>
                                                    <div className="card">
                                                        <div className="card2">
                                                            <div className="card-body">
                                                                <p className="trait-type"> {trait_type} </p>
                                                                <p className="trait-value"> {value} </p>
                                                                <p className="rarity"> {rarity} </p>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    ) 
                    :(  
                        <div>
                            <div className="reveal-container">
                                <img src={tokenData.metadata.image} alt="Unrevealed Suddenly Unicorns Token"/>
                                <h2> will be revealed soon enough! </h2>
                            </div>
                        </div>
                    )}
                    {authenticated ? (
                        <div>
                            {(holderNfts && holderNfts.ownedNfts && holderNfts.ownedNfts.length) ? (
                                <div className="owned-nfts-container">
                                    <h3> All your Suddenly Unicorns NFTs</h3>
                                    <div className="owned-nfts">
                                        {holderNfts.ownedNfts.map(({metadata, title}) => {
                                            return (
                                                <a key={metadata.tokenId} href={`https://www.suddenlyunicorns.com/token/${metadata.tokenId}`}> 
                                                    <div>
                                                        <p>#{metadata.tokenId}</p>
                                                    </div>
                                                    <div>
                                                        <img src={metadata.image} alt={title} />
                                                    </div>
                                                    {/* {metadata.attributes.map(({value, trait_type}) => {
                                                        if(trait_type === "Background Color") {
                                                            return(value)
                                                        }
                                                    })} */}
                                                </a>
                                            )
                                        })} 
                                    </div>
                                </div>
                            ) : ('')}
                        </div>
                    ) : (
                        <div className="bottom-message">
                            <p> This page shows token rarity based on the tokens minted so far.</p>
                            <p> Token holders can authenticate and see the final rarity.</p>
                        </div>
                    )}
                    

                    <div className="links-container">
                        <a href={`https://etherscan.io/nft/${process.env.REACT_APP_CONTRACT_ADDRESS}/${tokenId}`} target="_blank" rel="noreferrer noopener"> #{tokenId} on Etherscan</a>
                        <a href={`https://opensea.io/assets/${process.env.REACT_APP_CONTRACT_ADDRESS}/${tokenId}`} target="_blank" rel="noreferrer noopener"> #{tokenId} on Opensea</a>
                    </div>
                    {/* <a href={`https://rinkeby.etherscan.io/nft/${process.env.REACT_APP_CONTRACT_ADDRESS}/${tokenId}`} target="_blank" rel="noreferrer noopener"> Etherscan NFT page</a>
                    <a href={`https://testnets.opensea.io/assets/${process.env.REACT_APP_CONTRACT_ADDRESS}/${tokenId}`} target="_blank" rel="noreferrer noopener"> Opensea NFT page</a> */}
                </div>
            )
            :( 
                hasError ? (
                    <div>
                        <h1> Suddenly Unicorns </h1>
                        <h2> {errorMessage}</h2>
                    </div>
                )
                :( 
                    <div>
                        <h1> Suddenly Unicorns </h1>
                        <Spinner/>
                    </div>
                )
            )}
            <Outlet />
        </section>
    );
};

export default Token;