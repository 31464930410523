import { useEffect, useState, useRef, lazy, Suspense } from "react";
import { connectWallet, getCurrentWalletConnected, getInstallMessage,  getCookie, setCookie} from "./js/interact.js"
import Navigation from "./components/Navigation";
import Landing from "./components/Landing";
import Intro from "./components/Intro";
import MintingForm from "./components/MintingForm";
import { ParallaxBanner } from 'react-scroll-parallax';
import rainbowDesktop from "./images/background/rainbow.png";
import rainbowMobile from "./images/background/rainbow-mobile.png";
import cloudsUrl from "./images/background/clouds.png";
import lazysizes from 'lazysizes';
import PopupImage from './images/banners/popup_image.png';
import PopupImageMobile from './images/banners/popup_image_mobile.png';

const Minter = (props) => {
  // Global Variables
  const expectedNetwork = process.env.REACT_APP_EXPECTED_NETWORK;
  const dappLink = process.env.REACT_APP_DAPP_WEBSITE;

  // Popup
  const popupData = {
    enablePopup: true,
    popupShowTime: 5000,
    popupCookieName: 'dapp-popup',
    popupLink: dappLink,
    popupAlt: 'Suddenly Unicorns DAPP',
    popupIsLarge: false,
  }

  // State Variables
  const [walletAddress, setWallet] = useState(false);
  const [status, setStatus] = useState(false);
  const [pixelFont, setPixelFont] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // Refs
  const hamburgerRef = useRef(null);
  const unicornLeftRef = useRef(null);
  const unicornRightRef = useRef(null);
  const unicornMintRef = useRef(null);
  const pixelUnicornRef = useRef(null);
  
  // Lazy Loading Components
  const Contact = lazy(() => import("./components/Contact"));
  const Roadmap = lazy(() => import("./components/Roadmap"));
  const UnicornRotateParallax = lazy(() => import("./components/UnicornRotateParallax"));
  const Faq = lazy(() => import("./components/Faq"));
  const Team = lazy(() => import("./components/Team")); 
  const ScrollingColorBackground = lazy(() => import("react-scrolling-color-background")); 

  // Colors
  const blue1 = 'rgb(31,140,241)';
  const purple2 = 'rgb(67,47,207)';
  // const blue2 = 'rgb(174,234,255)';
  // const blue3 = 'rgb(20,92,158)';
  // const purple1 = 'rgb(55,35,70)';
  // const purple3 = 'rgb(114,78,145)';
  // const green1 = 'rgb(32, 202, 172)';

  const handleScroll = () => {
    var unicornMintBounds = unicornMintRef.current.getBoundingClientRect();
    var pixelUnicornBounds = pixelUnicornRef.current.getBoundingClientRect();
    unicornLeftRef.current.classList.remove('unicorn-colorful');
    unicornRightRef.current.classList.remove('unicorn-colorful');

    try {
      if(unicornMintBounds.top < 55 && unicornMintBounds.bottom > 400) {
        unicornLeftRef.current.classList.add('unicorn-mint');
        unicornRightRef.current.classList.add('unicorn-mint');
      }
      else {
        unicornLeftRef.current.classList.remove('unicorn-mint');
        unicornRightRef.current.classList.remove('unicorn-mint');
      }

      if(pixelUnicornBounds.top > pixelUnicornBounds.height * -1) {
        pixelUnicornRef.current.classList.add('complete');
      }
      else {
        pixelUnicornRef.current.classList.remove('complete');
      }
    }
    catch(err) {
      
    }
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        if(window.ethereum.chainId !== expectedNetwork) {
          setStatus('❗Please switch to mainnet to continue');
        }
        else {
          setStatus();
        }
      });

      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the button below.");
        }
      });
    } else {
      setStatus(getInstallMessage().status);
    }
  }

  const connectWalletPressed = async (event, isNavigation) => {
    event.preventDefault();
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    if(walletResponse.isMobile) {
      if(walletResponse.mobileLink !== undefined) {
        window.location = walletResponse.mobileLink;
      }
      else if(isNavigation) {
        hamburgerRef.current.click();
      }
    }

    if(walletResponse.status !== undefined) {
      document.getElementById('mintFormContainer').scrollIntoView({behavior: 'smooth' });
    }
    return;
  };

  useEffect(() => {
    async function getCurrentWalletConnectedAsync() {
      const { address, status } = await getCurrentWalletConnected();
      setWallet(address);
      setStatus(status);
      addWalletListener();
      if(address.length > 0 && window.ethereum.chainId !== expectedNetwork) {
        setStatus('❗Please switch to mainnet to continue');
      }
    }
    getCurrentWalletConnectedAsync();
  }, []);

  useEffect(() => {
    let pixel = localStorage.getItem('pixelFont');
    if(pixel === "false") {
      setPixelFont(false);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('pixelFont', pixelFont);
  });

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  },);

  const setStatusChild = (status) => {
    setStatus(status);
  };

  useEffect(() => {
    if(popupData.enablePopup && !showPopup) {
      var popupCookie = getCookie(popupData.popupCookieName);
        if (!popupCookie) {
          setTimeout(() => {
            setShowPopup(true);
            setCookie(popupData.popupCookieName, 'true', 365);
          }, (popupData.popupShowTime || 5000));
        }
    }
  }, [showPopup]);

  const onPopupClose = () => {
    setShowPopup(false);
  };

  const rainbowUrl = window.innerWidth >= 768 ? rainbowDesktop : rainbowMobile;

  return (
    
    <div id="mainDiv" className={`${!pixelFont ? "disable-pixel-font" : ""} ${showPopup ? 'popup-open' : ''}`} >
      <Suspense fallback={''}>
        <ScrollingColorBackground
          selector='.js-color-stop[data-background-color]'
          colorDataAttribute='data-background-color'
          initialRgb='rgb(0, 0, 0)'
        />
      </Suspense>

      <Navigation hamburgerRef={hamburgerRef} unicornRightRef={unicornRightRef} unicornLeftRef={unicornLeftRef} walletAddress={walletAddress} connectWalletPressed={connectWalletPressed} status={status} />

      <div className={`popup-container ${showPopup ? 'open' : 'close'}`} open={showPopup}>
        <div className="popup">
          <div className="popup-content">
            <div className="header">
              <div className="close-common">
                <button className="close-button" onClick={onPopupClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 2.33333 2.33333">
                    <g>
                      <path fill="#fefefe" d="M1.78195 2.01984c-0.0637795,0 -0.123575,-0.0246772 -0.168386,-0.0695l-0.446902 -0.44687 -0.446886 0.446886c-0.0448268,0.0448307 -0.104626,0.0695 -0.168406,0.0695 -0.0637795,0 -0.123591,-0.0246772 -0.168402,-0.0695 -0.0928465,-0.0928583 -0.0928465,-0.243941 0,-0.336803l0.446902 -0.446886 -0.446902 -0.446886c-0.044811,-0.0448268 -0.0695,-0.104634 -0.0695,-0.168402 0,-0.0637717 0.024689,-0.123575 0.0695,-0.168402 0.044811,-0.044815 0.104622,-0.0695 0.168386,-0.0695 0.0637795,0 0.123594,0.024685 0.168406,0.0695l0.446902 0.446886 0.446894 -0.446886c0.044811,-0.044815 0.10461,-0.0695 0.168406,-0.0695 0.0637795,0 0.123575,0.024685 0.168386,0.0695 0.0928622,0.0928583 0.0928622,0.243933 0,0.336787l-0.446882 0.446902 0.446886 0.446886c0.0928622,0.0928622 0.0928622,0.243945 0,0.336803 -0.0448268,0.044815 -0.104638,0.0694843 -0.168402,0.0694843z"/>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            
            <a href={popupData.popupLink} onClick={onPopupClose} target="_blank" rel="noreferrer nofollow">
              <div className="body">
                  <button className="popup-button">
                    <span> <span className="additional flip">💎</span> Visit our brand new DAPP! <span className="additional">💎</span>  </span> 
                  </button>
                  
                  <img className="desktop-image" src={PopupImage} alt={popupData.popupAlt} />
                  <img className="mobile-image" src={PopupImageMobile} alt={popupData.popupAlt}/>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="mainContentContainer">  
        <a name="home" href="#home" className="anchor-target">home</a>
        <Landing rainbowUrl={rainbowUrl} cloudsUrl={cloudsUrl} ref={pixelUnicornRef} />
        <Intro ref={pixelUnicornRef} />
        
        <MintingForm setStatusChild={setStatusChild} status={status} walletAddress={walletAddress}  connectWalletPressed={connectWalletPressed} unicornMintRef={unicornMintRef} />

        <section className='empty-section js-color-stop' data-background-color={blue1}></section>
        <section className='empty-section js-color-stop' data-background-color={purple2}></section>

        <Suspense fallback={<section><h2> Components loading up..</h2></section>}>  
          <ParallaxBanner className="rainbow-container"
              layers={[
                  { image: cloudsUrl, speed: -15, style:{inset:'-50px 0'} },
                  { 
                    image: rainbowUrl, speed: 5 , style:{inset:'-20px 0'}
                  }
              ]}
              style={{ aspectRatio: '2 / 1'}}>
              <UnicornRotateParallax></UnicornRotateParallax>
          </ParallaxBanner>
        
          <Contact />
          <Roadmap />
          
          <section className='empty-section js-color-stop' data-background-color={purple2}></section>
          <section className='empty-section js-color-stop' data-background-color={blue1}></section>
          
          <Faq/>
          <Team/>
        </Suspense>
        

        <section id="footerContainer">
              <p> Wow, you came this far and are actually reading this? Respect!</p>
              {pixelFont === true ? (
                <p> If you want to view the site with a regular font, <span className="clickable-span" onClick={() => setPixelFont(false)}> click here </span> </p>
              ) : (
                <p> If you want to view the site with the pixel font, <span className="clickable-span" onClick={() => setPixelFont(true)}> click here </span> </p>
              )}
              <p> Have you tried petting the unicorns on top? Sure they'd love it. </p>
              <p> See you on the other side! </p>

        </section>
      </div>
    </div>
  );
};

export default Minter;
